<template>
    <a-card>
        <a-form :form="companyObj">
             <a-form-item 
                label="公司名称" 
                :label-col="{span:6}" 
                :wrapper-col="{ span: 12 }"
                >
                <a-input
                    v-model="companyObj.name"
                    placeholder="请输入公司名称"
                    :disabled="true"
                />
            </a-form-item>
            <a-form-item 
                label="上午工作时间"
                :label-col="{span:6}" 
                :wrapper-col="{ span: 12 }"
                >
                <a-time-picker :value="work_am[0]?moment(work_am[0],'HH:mm'):null" placeholder="开始时间" format="HH:mm" @change="updateAMStartTime" /> - <a-time-picker :value="work_am[1]?moment(work_am[1],'HH:mm'):null" placeholder="结束时间" format="HH:mm" @change="updateAMEndTime"/>
            </a-form-item>
            <a-form-item 
                label="下午工作时间"
                :label-col="{span:6}" 
                :wrapper-col="{ span: 12 }"
                >
                <a-time-picker :value="work_pm[0]?moment(work_pm[0],'HH:mm'):null" placeholder="开始时间" format="HH:mm" @change="updatePMStartTime" /> - <a-time-picker :value="work_pm[1]?moment(work_pm[1],'HH:mm'):null" placeholder="结束时间" format="HH:mm" @change="updatePMEndTime"/>
            </a-form-item>
            <a-form-item 
                label="欢迎语"
                :label-col="{span:6}" 
                :wrapper-col="{ span: 14 }"
                >
                <a-row>
                    <a-button type="primary" size="small" style="font-size:12px" @click="handleAddClick('wel')">新增</a-button>
                </a-row>
                <a-table
                    :columns="settingColumns" 
                    :data-source="welList"
                    :row-key="record => record._id"
                    :pagination="false"
                    size="small"
                    :bordered="false"
                >
                 <a-tag slot="type" slot-scope="text" color="blue">{{formatType(text)}}</a-tag>
                 <template slot="content" slot-scope="text,record">
                     <div v-if="record.type=='sendText'">{{text}}</div>
                     <img v-if="record.type=='sendImage'" :src="text" style="width:30px;height:30px" />
                     <video v-if="record.type=='sendVideo'" :src="text" style="width:30px;height:30px"></video>
                     <div v-if="record.type=='sendLink'">
                         <img  :src="record.content.image_url" :title="record.content.content" style="width:30px;height:30px" />
                     </div>
                     <div v-if="record.type=='sendApp'">{{record.content.title}}</div>
                 </template>
                 <template slot="operation" slot-scope="text,record,index">
                    <div class="operation-wrapper">
                        <a-button type="link" style="color:#ff4d4f;" @click="handleDeleteOne('welList',index)">删除</a-button>
                    </div>  
                </template>
                </a-table>
            </a-form-item>
            <a-form-item 
                label="自动回复"
                :label-col="{span:6}" 
                :wrapper-col="{ span: 14 }"
            >
               <a-row>
                    <a-button type="primary" size="small" style="font-size:12px" @click="handleAddClick('auto')">新增</a-button>
                </a-row>
                <a-table
                    :columns="settingColumns" 
                    :data-source="autoList"
                    :row-key="record => record._id"
                    :pagination="false"
                    size="small"
                    :bordered="false"
                >
                    <a-tag slot="type" slot-scope="text" color="blue">{{formatType(text)}}</a-tag>
                 <template slot="content" slot-scope="text,record">
                     <div v-if="record.type=='sendText'">{{text}}</div>
                     <img v-if="record.type=='sendImage'" :src="text" style="width:30px;height:30px" />
                     <video v-if="record.type=='sendVideo'" :src="text" style="width:30px;height:30px"></video>
                     <div v-if="record.type=='sendLink'">
                         <img  :src="record.content.image_url" :title="record.content.content" style="width:30px;height:30px" />
                     </div>
                     <div v-if="record.type=='sendApp'">{{record.content.title}}</div>
                 </template>
                 <template slot="operation" slot-scope="text,record,index">
                    <div class="operation-wrapper">
                        <a-button type="link" style="color:#ff4d4f;" @click="handleDeleteOne('autoList',index)">删除</a-button>
                    </div>  
                </template>
                </a-table>
            </a-form-item>
            <a-form-item 
                label="邀请码"
                :label-col="{span:6}" 
                :wrapper-col="{ span: 12 }"
            >
                <a-input
                    v-model="companyObj.verify_code"
                    placeholder="请输入邀请码"
                />
            </a-form-item>
            <a-form-item 
                label="是否启用自动回复"
                :label-col="{span:6}" 
                :wrapper-col="{ span: 12 }"
            >
                <a-switch :disabled="true" v-model="companyObj.auto_reply" />(非工作时间)
            </a-form-item>
            <a-form-item style="text-align:center">
                <a-button type="primary" @click="handleSave" >保存</a-button>
            </a-form-item>
        </a-form>
        <a-modal v-model="visible" :footer="null">
            <div class="tab-view">
                    <div class="tab-item" :class="{'active':activeTab==='sendText'}" @click="handleChangeTab('sendText')">文本</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendImage'}" @click="handleChangeTab('sendImage')">图片</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendVideo'}" @click="handleChangeTab('sendVideo')">视频</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendLink'}" @click="handleChangeTab('sendLink')">链接</div>
                    <div class="tab-item" :class="{'active':activeTab==='sendApp'}" @click="handleChangeTab('sendApp')">小程序</div>
                </div>
                <a-input-search
                    placeholder="标题"
                    enter-button="搜索"
                    size="large"
                    @search="handleClickSearch"
                    />
                <div class="text-view tab-content" v-if="activeTab==='sendText'">
                    <div class="text-item" v-for="(item,index) in resList" :key="index">
                        <div class="text-title">{{item.title}}</div>
                        <div class="text-desc">{{item.content}}</div>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="img-view tab-content" v-if="activeTab==='sendImage'">
                    <div class="img-item" v-for="(item,index) in resList" :key="index">
                        <img :src="item.content" class="img"/>
                        <p class="title">{{item.title}}</p>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="img-view tab-content" v-if="activeTab==='sendVideo'">
                    <div class="img-item" v-for="(item,index) in resList" :key="index">
                        <video :src="item.content" class="img" ></video>
                        <p class="title">{{item.title}}</p>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="link-view tab-content" v-if="activeTab==='sendLink'">
                    <div class="link-item" v-for="(item,index) in resList" :key="index">
                        <div class="title">{{item.content.title}}</div>
                        <div class="content">
                            <div class="desc">{{item.content.desc}}</div>
                            <img :src="item.content.image_url" class="link-img"/>
                        </div>
                        <button class="use-btn" @click="handleClickUse(item)">选择</button>
                    </div>
                </div>
                <div class="app-view tab-content" v-if="activeTab==='sendApp'">
                    <div class="app-item" v-for="(item,index) in resList" :key="index">
                        <div class="title">
                               <img :src="item.content.thumburl" class="logo-img"/>
                               <span class="app-name">{{item.content.title}}</span>
                           </div>
                           <div class="content">
                               <p class="desc">{{item.content.des || '   '}}</p>
                               <img :src="item.content.image" class="main-img"/>
                           </div>
                        <button class="use-btn" @click="handleClickUse(item)">发送</button>
                    </div>
                </div>
                <div class="btn-view">
                    <button class="btn" :disabled="!hasPrev" @click="handleClickPrev">上一页</button>
                    <button class="btn" :disabled="!hasNext" @click="handleClickNext">下一页</button>
                </div>
        </a-modal>
    </a-card>
</template>
<script>
const settingColumns = [
    {
        title: '类型',
        dataIndex: 'type',
        align:'center',
        scopedSlots: { customRender: "type" }
    },
    {
        title: '内容',
        dataIndex: 'content',
        align:'center',
         scopedSlots: { customRender: "content" }
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
import {
    mapState
  } from 'vuex'
  import moment from 'moment';
export default {
    name:'setting',
    data(){
        return{
            companyObj:{
                name:'',
                auto_reply:false,
                verify_code:''
            },
            settingColumns,
            work_am:[],
            work_pm:[],
            welList:[],
            autoList:[],
            resList:[],
            visible:false,
            addType:'',
            pageNum:1,
            pageSize:10,
            activeTab:'sendText',
            searchParam:'',
            hasPrev:false,
            hasNext:false
        }
    },
    computed: {
        ...mapState(['mqttInit','company'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleGetCompany()
                }
            },
            immediate:true
        }
    },
    methods:{
         moment,
         handleSave(){
             let data = {}
             data._id = this.companyObj._id
             data.verify_code = this.companyObj.verify_code
             data.auto_reply = this.companyObj.auto_reply
             data.auto_reply = false
             data.work_am = this.work_am
             data.work_pm = this.work_pm
             data.haeremai = this.welList.map(item=>item._id)
             data.reply_msg = this.autoList.map(item=>item._id)
              let payload = {
                msgId:'updateCom',
                data
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/auth/updateCom",
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        this.$message.success('更新成功')
                    }
                },
                this
            );
         },
        handleGetCompany(){
            let payload = {
                msgId:'searchCompanyObj',
                data:{
                    code:[this.company],
                    page:1
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/auth/searchCom",
                payload,
                },
                (topic, data) => {
                    this.companyObj = {...this.companyObj,...data.res[0],...{auto_reply:false}}
                    this.work_am = data.res[0].work_am || []
                    this.work_pm = data.res[0].work_pm || []
                    this.welList = data.res[0].haeremai || []
                    this.autoList = data.res[0].reply_msg||[]
                },
                this
            );
        },
        updatePMEndTime(t,ts){
            this.$set(this.work_pm,1,ts)
        },
        updatePMStartTime(t,ts){
            
            this.$set(this.work_pm,0,ts)
        },
        updateAMEndTime(t,ts){
            this.$set(this.work_am,1,ts)
        },
        updateAMStartTime(t,ts){
            this.$set(this.work_am,0,ts)
        },
        formatType(type){
            switch(type){
                case 'sendText':
                    return '文本'
                case 'sendImage':
                    return '图片'
                case 'sendVideo':
                    return '视频'
                case 'sendLink':
                    return '链接'
                case 'sendApp':
                    return '小程序'            
            }
        },
        handleAddClick(type){
            this.addType = type
            this.handleGetMaterial()
            this.visible = true
        },
        handleDeleteOne(key,index){
            this[key].splice(index,1)
        },
        //点击上一页
        handleClickPrev(){
            this.pageNum -- 
            this.handleGetMaterial()
        },
         //点击下一页
        handleClickNext(){
            this.pageNum ++ 
            this.handleGetMaterial()
        },
        handleClickSearch(value){
            this.searchParam = value
            this.pageNum = 1
            this.handleGetMaterial()
        },
        handleChangeTab(val){
            this.activeTab = val
            this.pageNum = 1
            this.searchParam = ''
            this.handleGetMaterial()
        },
         //查询素材
        handleGetMaterial(){
            let topic = 'scrm/materialMgtService/searchMaterialByPage'
            let payload = {
                msgId:'searchMaterialByPage',
                data: {
                    "type":this.activeTab,
                    "title":this.searchParam,
                    "pageNum":this.pageNum,
                    "pageSize":this.pageSize,
                },
            };
             this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                    this.resList = data.data.res
                    let count = data.data.count
                    this.hasPrev = this.pageNum>1
                    this.hasNext = count>this.pageNum*this.pageSize
                },
                this
            );
        },
        handleClickUse(item){
            if(this.addType=='wel'){
                let arr = this.welList.filter(obj=>obj._id===item._id)
                if(arr.length>0){
                    this.$message.warning('禁止多次添加同一个素材')
                    return
                }
                this.welList.push(item)
            }else{
                let arr = this.autoList.filter(obj=>obj._id===item._id)
                if(arr.length>0){
                    this.$message.warning('禁止多次添加同一个素材')
                    return
                }
                this.autoList.push(item)
            }
            this.visible = false
        }
    }
}
</script>
<style lang="scss" scoped>
.tab-view{
        margin-top: 20px;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #999;
        // border-bottom: 1px solid #e7e7e7;
        .tab-item{
            height: 50px;
            line-height: 50px;
            cursor: pointer;
        }
        .tab-item.active{
            color: #48A5A1;
            font-weight: bold;
        }
    }
    .tab-content{
        height: calc(100% - 55px - 60px - 35px);
        box-sizing: border-box;
        overflow-y: auto;
    }
    .img-view{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;

    }
    .search-content{
        box-sizing: border-box;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        .icon-search{
            position: absolute;
            // top:22px;
            left:28px;
            color: #999;
        }
        .inp{
            box-sizing: border-box;
            border:0px;
            background-color: #DBD9D8;
            height: 30px;
            width: 200px;
            padding-left: 30px;
            border-radius: 3px;
        }
        .btn{
            background-color: #48A5A1;
            color: #fff;
            font-size: 12px;
            border-radius: 3px;
            border:0px;
            height: 30px;
            line-height: 30px;
            padding: 0px 10px;
            cursor: pointer;
        }
    }
    .btn-view{
        display: flex;
        justify-content: space-around;
        padding-top: 5px;
        .btn{
            border: 0px;
            background-color:#48A5A1;
            color: #fff;
            width: 60px;
            height: 30px;
            border-radius: 3px;
            cursor: pointer;
        }
        .btn:disabled{
            background-color: #eee;
            color:#999;
            cursor: not-allowed;
        }
    }
    .text-item{
        padding: 10px;
        box-sizing: border-box;
        border-bottom:1px solid #efefef;
        position: relative;
        .text-title{
            font-size: 14px;
            color: #333;
            font-weight: bold;
            margin-bottom: 5px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .text-desc{
            font-size: 12px;
            color: #999;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
    }
    .img-item{
        width: 75px;
        height: 95px;
        padding: 5px;
        border: 1px solid #efefef;
        border-radius: 2px;
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;
        .img{
            width: 100%;
            height: 62px;
        }
        .title{
            font-size: 12px;
            text-align: center;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
    }
    .img-item:nth-child(3n){
        margin-right: 0px;
    }
    .link-view{
        display: flex;
       flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;
    }
    .link-item{
        width: 200px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
        .title{
            // font-size: 16px;
            color: #333;
            height: 42px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            // line-height: 30px;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
        .content{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .desc{
                box-sizing: border-box;
               height: 50px;
               width: 140px; 
               font-size: 12px;
               color: #999;
               display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                word-break: break-all;
                word-wrap: break-word;
                overflow: hidden;
            }
            .link-img{
                width: 50px;
                height: 50px;
            }
        }
    }
    .app-view{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;
    }
    .app-item{
        width: 200px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
       
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
       .title{
            height: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            .logo-img{
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .app-name{
                font-size: 13px;
                color:#999;
                padding-left: 10px;
            }
        }
        .content{
            .desc{
                color: #333;
                font-size: 13px;
                height: 19px;
            }
            .main-img{
                width: 100%;
                height: 100px;
            }
        }
    }

</style>